import React from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import { graphql, ApolloProvider, Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import { navigate } from "gatsby";
import Captcha from "components/common/Captcha";
import ReactHtmlParser from "react-html-parser";
import ReactLoading from "react-loading";
import LoginBindAlertLightBox from "components/login/LoginBindAlertLightBox";
import LoginBindLightBox from "components/login/LoginBindLightBox";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

const setLogin = gql`
  mutation setLogin($form: LoginForm) {
    result: setLogin(Input: $form) {
      success
      errorType
      sessionID
    }
  }
`;

const getLoginConfig = gql`
  query getLoginConfig($configType: String) {
    getLoginConfig(Input: $configType) {
      backToHome
      guestLogin
      guestLoginMessage
      hasCaptcha
      hasLogo
    }
  }
`;

@inject("readerStore", "appStore")
@observer
class LoginContent extends React.Component {
  constructor(props) {
    super(props);
    let loginUserTypesString =
      props.appStore.globalConfig["jumperrwd.login.userTypes"];
    let userInputType =
      props.appStore.globalConfig["jumperrwd.login.userInputType"];
    let notHiddenInputUserTypeString =
      props.appStore.globalConfig["jumperrwd.login.notHiddenInputUserType"];
    let alertHiddenString =
      props.appStore.globalConfig["jumperrwd.login.alertHidden"];
    let alertHidden = alertHiddenString === "1";
    if (userInputType !== "1") {
      userInputType = "0";
    }
    let loginUserTypes = null;
    if (loginUserTypesString !== undefined && loginUserTypesString !== "") {
      loginUserTypes = loginUserTypesString.split(",");
    }
    let userType = "";
    let bindUserType = "";
    if (loginUserTypes !== null) {
      userType = loginUserTypes[0];
      if (loginUserTypes.length > 1) {
        bindUserType = loginUserTypes[1];
      }
    }
    let notHiddenInputUserTypes = [];
    if (
      notHiddenInputUserTypeString !== undefined &&
      notHiddenInputUserTypeString !== ""
    ) {
      notHiddenInputUserTypes = notHiddenInputUserTypeString.split(",");
    }
    let notHiddenUser = notHiddenInputUserTypes.includes(userType);

    this.state = {
      user: "",
      userHidden: "",
      userInputType: userInputType,
      notHiddenInputUserTypes: notHiddenInputUserTypes,
      notHiddenUser: notHiddenUser,
      password: "",
      captcha: "",
      userType: userType,
      bindUserType: bindUserType,
      loginUserTypes: loginUserTypes,
      loginMessage: "",
      loginDisable: false,
      processLogin: false,
      captchRefetch: null,
      alertHidden: alertHidden,
    };
  }

  componentDidMount() {
    if (!this.props.hiddenInput) {
      this.usernameInput.focus();
    }
  }

  @autobind
  handleUser(e) {
    if (this.state.userInputType === "0" || this.state.notHiddenUser) {
      this.setState({
        user: e.target.value,
      });
    } else if (this.state.userInputType === "1") {
      let userValue = e.target.value;
      let hiddenUserValue = this.state.userHidden;

      let showLength = 1;

      let offset = userValue.length - hiddenUserValue.length;
      let userHidden = hiddenUserValue;

      if (offset > 0) {
        userHidden =
          hiddenUserValue +
          userValue.substring(
            hiddenUserValue.length,
            hiddenUserValue.length + offset
          );
      } else if (offset < 0) {
        userHidden = hiddenUserValue.substring(
          0,
          hiddenUserValue.length + offset
        );
      }

      // Change the visible string
      this.setState({
        user:
          userValue
            .substring(0, userValue.length - showLength)
            .replace(/./g, "•") +
          userValue.substring(userValue.length - showLength, userValue.length),
        userHidden,
      });

      // Set the timer
      clearTimeout(this.hideAll);
      this.hideAll = setTimeout(() => {
        this.setState({
          user: userValue.replace(/./g, "•"),
        });
      }, 1000);
    }
  }

  @autobind
  handlePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  @autobind
  handleCaptcha(e) {
    this.setState({
      captcha: e.target.value,
    });
  }

  @autobind
  _reset() {
    this.setState({
      user: "",
      password: "",
      loginMessage: "",
      loginDisable: false,
      processLogin: false,
    });
  }

  @autobind
  async handleLogin(data) {
    if (data.result.success) {
      await this.props.readerStore.syncSessionCookie().then(() => {
        if (data.result.errorType === 11) {
          this.loginBindAlertLightBox.popup();
        } else {
          if (!this.state.alertHidden) {
            alert(this.props.t("jumperrwd.login.loginSuccess"));
          }
          if (this.props.isPage) {
            if (this.props.appStore.loginRedirect !== null) {
              navigate(this.props.appStore.loginRedirect);
            } else {
              navigate("/");
            }
          } else {
            this.props.close();
          }
        }
      });
    } else {
      if (data.result.errorType === 1) {
        alert(this.props.t("jumperrwd.common.captchaError"));
      } else if (data.result.errorType === 12) {
        await this.props.readerStore.syncSessionCookie().then(() => {
          this.loginBindAlertLightBox.popup();
        });
      } else {
        alert(this.props.t("jumperrwd.login.loginFailed"));
      }
    }
    this.setState({ loginDisable: false, processLogin: false });
  }

  @autobind
  async setLogin() {
    await this.props.readerStore.syncSessionCookie().then(() => {
      if (this.props.isPage) {
        if (this.props.appStore.loginRedirect !== null) {
          navigate(this.props.appStore.loginRedirect);
        } else {
          navigate("/");
        }
      } else {
        this.props.close();
      }
    });
  }

  @autobind
  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  @autobind
  getCaptchRefetch(refetch) {
    this.setState({ captchRefetch: refetch });
  }

  @autobind
  toLib(e) {
    e.preventDefault();
    window.location.href =
      this.props.appStore.globalConfig["jumperrwd.login.libraryHome"] || "/";
  }

  @autobind
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  @autobind
  openLoginBindLightBox() {
    this.loginBindLightBox.popup();
  }

  @autobind
  bindAlertLightBoxClose() {
    this.loginBindAlertLightBox.close();
  }

  @autobind
  loginSubmit(setLogin, e) {
    e.preventDefault();
    let { loginConfig, hiddenInputType } = this.props;
    let { hasCaptcha } = loginConfig;
    if (hiddenInputType === "oauth") {
      navigate("/oauth?login=true");
    } else {
      if (
        this.state.user !== "" &&
        this.state.password !== "" &&
        (!hasCaptcha || (hasCaptcha && this.state.captcha !== ""))
      ) {
        this.setState({
          loginDisable: true,
          processLogin: true,
        });
        let user = this.state.user;
        if (this.state.userInputType === "1" && !this.state.notHiddenUser) {
          user = this.state.userHidden;
        }
        setLogin({
          variables: {
            form: {
              user,
              password: this.state.password,
              captcha: this.state.captcha,
              userType: this.state.userType,
            },
          },
        });
      } else if (this.state.user === "") {
        alert(this.props.t("jumperrwd.login.pleaseEnterUsername"));
      } else if (this.state.password === "") {
        alert(this.props.t("jumperrwd.login.pleaseEnterPwd"));
      } else if (hasCaptcha && this.state.captcha === "") {
        alert(this.props.t("jumperrwd.common.pleaseEnterCaptcha"));
      }
    }
  }

  @autobind
  toGuestLogin(e) {
    e.preventDefault();
    if (this.props.appStore.sendURL !== null) {
      window.location.href = this.props.appStore.sendURL;
      this.props.close();
    } else {
      if (this.props.isPage) {
        this.props.appStore.setCheckedGuest(1);
        axios.get("/api/jumperrwdWs/setSession?key=checkedGuest&value=1")
        .then((res) => { navigate("/"); });
      } else {
        this.props.close();
      }
    }
  }

  render() {
    let { t, appStore, loginConfig, hiddenInput } = this.props;
    let { header } = appStore;
    if (header === null || header === undefined) {
      return "";
    }
    let { loginUserTypes, notHiddenInputUserTypes } = this.state;
    let { languageList } = header;
    let { guestLogin, guestLoginMessage, hasCaptcha, hasLogo } = loginConfig;
    return (
      <>
        <div className="login_form">
          {!hiddenInput && (
            <h2>
              {t("jumperrwd.common.login")}{" "}
              <span className="name">{t("jumper.login.libname")}</span>
            </h2>
          )}
          {this.state.userType !== "" && (
            <div className="form_grp form_inline">
              <label htmlFor="userType">{t("jumperrwd.login.loginType")}</label>
              <select
                id="userType"
                value={this.state.userType}
                onChange={(e) => {
                  e.preventDefault();
                  let selectedUserType = e.target.value;
                  let { user, userHidden } = this.state;
                  let bindUserType = this.state.loginUserTypes.filter(function (
                    loginUserType
                  ) {
                    return loginUserType !== selectedUserType;
                  })[0];

                  let notHiddenUser =
                    notHiddenInputUserTypes.includes(selectedUserType);

                  if (this.state.userInputType === "1") {
                    if (notHiddenUser && !this.state.notHiddenUser) {
                      user = userHidden;
                    } else if (!notHiddenUser && this.state.notHiddenUser) {
                      userHidden = user;
                      user = user.replace(/./g, "•");
                    }
                  }
                  this.setState({
                    userType: selectedUserType,
                    bindUserType,
                    notHiddenUser,
                    user,
                    userHidden,
                  });
                }}>
                {loginUserTypes !== null &&
                  loginUserTypes.map((loginUserType) => {
                    return (
                      <>
                        <option value={loginUserType}>
                          {t("jumperrwd.loginUserType." + loginUserType)}
                        </option>
                      </>
                    );
                  })}
              </select>
            </div>
          )}
          <div className="login_instructions">
            <div className="pc">
              {this.state.userType !== ""
                ? ReactHtmlParser(
                    t("jumperrwd.login." + this.state.userType + "PcNote")
                  )
                : ReactHtmlParser(t("jumperrwd.login.pcNote"))}
            </div>
            <div className="mobile">
              {this.state.userType !== ""
                ? ReactHtmlParser(
                    t("jumperrwd.login." + this.state.userType + "MobileNote")
                  )
                : ReactHtmlParser(t("jumperrwd.login.mobileNote"))}
            </div>
          </div>
          <div className="error">
            {(() => {
              if (this.state.processLogin)
                return <center>{t("jumperrwd.login.processLogin")}</center>;
              else return "";
            })()}
          </div>
          <form>
            <div className="form_content">
              {!hiddenInput && (
                <>
                  {" "}
                  <div className="form_grp form_inline">
                    <label htmlFor="username">
                      {t("jumperrwd.login.username")}
                    </label>
                    <input
                      id="username"
                      name="username"
                      ref={(c) => {
                        this.usernameInput = c;
                      }}
                      type="text"
                      placeholder={
                        this.state.userType !== ""
                          ? t(
                              "jumperrwd.login.enter" +
                                this.capitalize(this.state.userType)
                            )
                          : t("jumperrwd.login.enterUsername")
                      }
                      value={this.state.user}
                      onChange={this.handleUser}
                    />
                  </div>
                  <div className="form_grp form_inline">
                    <label htmlFor="password">
                      {t("jumperrwd.login.password")}
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="off"
                      placeholder={
                        this.state.userType !== ""
                          ? t(
                              "jumperrwd.login.enter" +
                                this.capitalize(this.state.userType) +
                                "Pwd"
                            )
                          : t("jumperrwd.login.enterPwd")
                      }
                      value={this.state.password}
                      onChange={this.handlePassword}
                    />
                  </div>
                  <div className="form_grp form_inline">
                    {hasCaptcha && (
                      <>
                        {this.props.display !== "none" && (
                          <>
                            <Captcha typeName="login" t={this.props.t} />
                            <input
                              name="captcha"
                              title={t("jumperrwd.common.captcha")}
                              type="text"
                              value={this.state.captcha}
                              placeholder={t("jumperrwd.common.enterCaptcha")}
                              onChange={this.handleCaptcha}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              <ApolloProvider client={client.jumperrwdClient}>
                <Mutation
                  mutation={setLogin}
                  onError={() => {
                    this.setState({
                      loginMessage: "jumperrwd.login.loginError",
                    });
                  }}
                  onCompleted={(data) => this.handleLogin(data)}>
                  {(setLogin) => (
                    <div className="btn_grp">
                      <input
                        className=""
                        tabIndex="0"
                        type="submit"
                        ref="loginBtn"
                        title={t("jumperrwd.common.login")}
                        value={t("jumperrwd.common.login")}
                        disabled={this.state.loginDisable}
                        onClick={this.loginSubmit.bind(this, setLogin)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.loginSubmit(setLogin, e);
                          }
                        }}
                      />
                    </div>
                  )}
                </Mutation>
              </ApolloProvider>
            </div>
          </form>
          {guestLogin && (
            <div className="notSignin">
              <a
                className="customer"
                tabIndex="0"
                onClick={this.toGuestLogin.bind(this)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.toGuestLogin(e);
                  }
                }}>
                {t(guestLoginMessage)}
              </a>
            </div>
          )}
          {hasLogo && !hiddenInput && (
            <div className="copyright">
              <img src="/file/images/ebsco.png" alt="ebsco" />
              <img src="/file/images/logo_hyweb.png" alt="hyweb圖片" />
              <p>
                Embedded EBSCO Discovery Service
                <br />
                System Designed by Hyweb Technology Co., Ltd.
                <br />
                ©2020 All Rights Reserved
              </p>
            </div>
          )}
        </div>
        <div className="function_grp">
          <button
            type="button"
            tabIndex="0"
            className="btn"
            onClick={this.toLib.bind(this)}>
            {this.props.t("jumperrwd.login.backToLibHome")}
          </button>
          <div className="language">
            <select
              className=""
              id=""
              value={this.props.i18n.language}
              title="語言"
              onChange={(e) => {
                e.preventDefault();
                this.handleChangeLanguage(e.target.value);
              }}>
              {languageList.map((language) => {
                let { code, label } = language;
                return <option value={code}>{label}</option>;
              })}
            </select>
          </div>
        </div>
        <LoginBindAlertLightBox
          ref={(c) => {
            this.loginBindAlertLightBox = c;
          }}
          openLoginBindLightBox={this.openLoginBindLightBox}
          loginLightBoxClose={this.props.close}
          bindUserType={this.state.bindUserType}
          t={this.props.t}
          appStore={this.props.appStore}
        />
        <LoginBindLightBox
          ref={(c) => {
            this.loginBindLightBox = c;
          }}
          lightboxClass="login_lightbox"
          blockClass="loginblock binding"
          bindAlertLightBoxClose={this.bindAlertLightBoxClose}
          bindUserType={this.state.bindUserType}
          readerStore={this.props.readerStore}
          t={this.props.t}
        />
      </>
    );
  }
}

@withI18next(["common"])
class Login extends React.Component {
  constructor(props) {
    super(props);
    let opacity = 0;
    let display = "none";
    if (this.props.isPage) {
      opacity = 1;
      display = "block";
    }
    let hiddenInput = false;
    let hiddenInputType = "";
    let fcuClientID = props.appStore.globalConfig["fcu.clientid"] || "";
    if (fcuClientID !== "") {
      hiddenInput = true;
      hiddenInputType = "oauth";
    }
    this.state = {
      opacity: opacity,
      display: display,
      hiddenInput: hiddenInput,
      hiddenInputType: hiddenInputType,
    };
  }

  @autobind
  _openLogin() {
    this.setState({ display: "block" });
    setTimeout(() => this.setState({ opacity: 1 }), 10);
    document.body.classList.add("fix");
  }

  @autobind
  _close() {
    if (this.content) {
      this.content._reset();
    }
    if (this.props.appStore.sendURL !== null) {
      this.props.appStore.setSendURL(null);
    }
    this.setState({ opacity: 0 });
    setTimeout(() => {
      this.setState({ display: "none" });
      document.body.classList.remove("fix");
    }, 1000);
  }

  @autobind
  _overlay() {
    if (this.content) {
      this.content._reset();
    }
    if (this.props.appStore.sendURL !== null) {
      this.props.appStore.setSendURL(null);
    }
    this.setState({ opacity: 0 });
    setTimeout(() => {
      this.setState({ display: "none" });
      document.body.classList.remove("fix");
    }, 1000);
  }

  componentWillUnmount() {
    document.body.classList.remove("fix");
  }

  render() {
    let { t, isPage, appStore, search, i18n } = this.props;
    let { sendURL } = appStore;
    let { language } = i18n;
    let params = { ...search };
    if (isPage === undefined) {
      isPage = false;
    }
    let configType = "";
    if (isPage) {
      configType = "page";
    } else if (sendURL !== null) {
      if (params.type && params.type === "jcr") {
        configType = "jcr";
      } else {
        configType = "sendURL";
      }
    }
    return (
      <>
        <div
          className="login_lightbox"
          ref="login_lightbox"
          style={{
            opacity: this.state.opacity,
            transition: "opacity 1s",
            display: this.state.display,
          }}>
          <div className="overlay" onClick={isPage ? "" : this._overlay}>
            {isPage && (
              <img src="/file/images/login_bg.jpg" alt="背景圖片"></img>
            )}
          </div>
          {this.state.display !== "none" && (
            <ApolloProvider client={client.jumperrwdClient}>
              <Query
                query={getLoginConfig}
                fetchPolicy="network-only"
                variables={{
                  configType: configType,
                }}>
                {({ loading, error, data, refetch }) => {
                  if (loading)
                    return (
                      <center>
                        <ReactLoading
                          type="cylon"
                          height={"10%"}
                          width={"20%"}
                          color="#005D98"
                        />
                      </center>
                    );
                  if (error) return `Error!${error.message}`;

                  if (data.getLoginConfig) {
                    if (data.getLoginConfig.backToHome) {
                      navigate("/");
                    } else {
                      let tImageSrc = "",
                        defaultTSrc = "/file/images/logo.png";
                      if (language === "zh") {
                        tImageSrc = "/file/images/logo.png";
                      } else if (language === "en") {
                        tImageSrc = "/file/images/logo_en.png";
                      }
                      if (tImageSrc === "") {
                        tImageSrc = defaultTSrc;
                      }
                      return (
                        <div
                          className={
                            this.state.hiddenInput
                              ? "loginblock noform"
                              : "loginblock"
                          }>
                          {isPage ? (
                            <div className="main_img">
                              <h1 className="logo">
                                <img
                                  src={tImageSrc}
                                  alt={this.props.t("jumper.sys.name") + "Logo"}
                                />
                              </h1>
                              <div className="textblock">
                                <div className="text_content">
                                  {ReactHtmlParser(t("jumperrwd.login.about"))}
                                </div>
                              </div>
                              <div className="img-container">
                                <img
                                  src="/file/images/lib_free.png"
                                  alt="內部背景圖"
                                />
                              </div>
                              {this.state.hiddenInput && (
                                <LoginContent
                                  close={this._close}
                                  ref={(c) => {
                                    this.content = c;
                                  }}
                                  display={this.state.display}
                                  loginConfig={data.getLoginConfig}
                                  hiddenInput={this.state.hiddenInput}
                                  hiddenInputType={this.state.hiddenInputType}
                                  {...this.props}
                                />
                              )}
                            </div>
                          ) : (
                            <div
                              className="close"
                              tabIndex="0"
                              onClick={this._close}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this._close(e);
                                }
                              }}></div>
                          )}
                          {(!isPage || !this.state.hiddenInput) && (
                            <LoginContent
                              close={this._close}
                              ref={(c) => {
                                this.content = c;
                              }}
                              display={this.state.display}
                              loginConfig={data.getLoginConfig}
                              hiddenInput={this.state.hiddenInput}
                              hiddenInputType={this.state.hiddenInputType}
                              {...this.props}
                            />
                          )}
                        </div>
                      );
                    }
                  }
                  return "";
                }}
              </Query>
            </ApolloProvider>
          )}
        </div>
      </>
    );
  }
}

export default Login;
