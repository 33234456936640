import React from "react";
import { inject, observer } from "mobx-react";
import withLocation from "lib/withLocation";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import qs from "query-string";

export const page = (WrappedComponent) => {
  @withLocation
  @inject("readerStore", "appStore")
  @observer
  class pageComp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount() {}

    changeLangAttri(lang) {
      let langAttri = "zh-Hant-TW";
      if (lang === "zh") {
        langAttri = "zh-Hant-TW";
      } else if (lang === "en") {
        langAttri = "en-US";
      }
      return langAttri;
    }

    render() {
      let { readerStore, appStore, location, search } = this.props;
      let params = { ...search };
      let { auth, session } = readerStore; //需要取值，否則auth改變，WrappedComponent的props不會檢查
      let { filterPid, globalConfig, checkedGuest } = appStore;
      let needLogin =
        !auth &&
        (globalConfig["hyint.module.isIndexGuestLogin"] === "0" ||
          (globalConfig["jumper.common.guest.campus"] === "0" &&
            session.useUnitOutsite === "0") ||
          (globalConfig["jumper.common.guest.offcampus"] === "0" &&
            session.useUnitOutsite === "1") || (
            globalConfig["jumperrwd.login.guestCheck"] === "1" && session.checkedGuest !== "1" && checkedGuest !== 1
          ));
      if (
        this.props.appStore.loginRedirect !== null &&
        !location.pathname.includes("/login")
      ) {
        this.props.appStore.setLoginRedirect(null);
      }
      if (
        !location.pathname.includes("/sendURLApiV3") &&
        !location.pathname.includes("/searchRedirectEDS") &&
        !location.pathname.includes("/dLink") &&
        !location.pathname.includes("/oauth") &&
        needLogin &&
        !location.pathname.includes("/login")
      ) {
        let path =
          Object.keys(params).length > 0
            ? [location.pathname, qs.stringify(params)].join("?")
            : location.pathname;
        this.props.appStore.setLoginRedirect(path);
        navigate("/login");
      } else {
        let { language } = this.props.i18n;
        let gtmAccount = globalConfig["hyint.core.gtmAccount"] || "";
        return (
          <>
            <Helmet htmlAttributes={{ lang: this.changeLangAttri(language) }}>
              {gtmAccount !== "" && (
                <script>
                  {`
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({ platform: "gatsby" });
                  (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                    var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s),
                      dl = l != "dataLayer" ? "&l=" + l : "";
                    j.async = true;
                    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl + "";
                    f.parentNode.insertBefore(j, f);
                  })(window, document, "script", "dataLayer", "` +
                    gtmAccount +
                    `");
                `}
                </script>
              )}
              <title>{this.props.t("jumper.sys.name")}</title>
              <link rel="icon" href={"/file/images/favicon.png"} />
            </Helmet>
            <WrappedComponent {...this.props} />
          </>
        );
      }
      return <></>;
    }
  }
  return pageComp;
};
