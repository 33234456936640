import React from "react";
import querystring from "query-string";
import { Link } from "gatsby";

class LinkDomain extends React.Component {
  render() {
    let navigate = false;
    let { query, url } = querystring.parseUrl(this.props.href || "");

    if (!/^http[s]?\:\/\//.test(url) && url !== "") {
      if (!url.startsWith("/")) {
        url = "/" + url;
      }
      navigate = true;
    }

    let urls = [url];
    let querystringstr = querystring.stringify(query);
    if (querystringstr !== "") {
      urls.push(querystringstr);
    }

    let title = (this.props.target === "_blank" || this.props.target === "_BLANK")?"另開新視窗":"";
    if (this.props.title !== undefined && this.props.title !== "") {
      if (title !== "") {
        title = "(" + title + ")";
      }
      title = this.props.title + title;
    }
    if (navigate) {
      if (this.props.target !== "_blank" && this.props.target !== "_BLANK" ) {
        return (
          <Link
            to={urls.join("?")}
            className={this.props.className || ""}
            target={this.props.target || ""}
            title={title}
            state={this.props.state}
            >
            {this.props.children}
          </Link>
        );
      }
    } else if (urls.join("?") === "") {
      return (
        <a className={this.props.className || ""}>{this.props.children}</a>
      );
    }

    return (
      <a
        href={urls.join("?")}
        className={this.props.className || ""}
        target={this.props.target || ""}
        title={title}>
        {this.props.children}
      </a>
    );
  }
}

export default LinkDomain;
