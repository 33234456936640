import React from "react";
import i18next from "./i18n";
import { I18nextProvider, withNamespaces } from "react-i18next";
import { autobind } from "react-decoration";

export const withI18next = (namespaces = ["common"], isprivate = false) => (
  WrappedComponent
) => {
  WrappedComponent = withNamespaces(namespaces, { innerRef: ref => {
    if (ref) {
      ref.props.setRef(ref);
    }
  } })(WrappedComponent);
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.wrappedComponent = React.createRef();
    }

    @autobind
    setRef(ref) {
      this.wrappedComponent = ref;
    }
  
    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent
            {...this.props}
            setRef={this.setRef}
            language={i18next.language}
            namespaces={namespaces}
            isprivate={isprivate}
          />
        </I18nextProvider>
      );
    }
  };
};
