import i18next from "i18next";
import React from "react";

const LightBox = (Wrapped, Config) =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        title: null,
        display: "none",
        opacity: 0,
      };

      this.setTitle = this.setTitle.bind(this);
      this.pageYOffset = 0;
    }

    t = (key, param = {}) => {
      return i18next.t(key, param);
    };

    componentDidMount() {
      this.addKeyDownListener();
    }

    componentWillUnmount() {
      document.body.classList.remove("fix");
      this.removeKeyDownListener();
    }

    popup = () => {
      this.pageYOffset = window.pageYOffset;
      const { t } = this;
      console.log(this.refs.wrapped.state);
      if (
        this.refs.wrapped.state.title != null &&
        this.refs.wrapped.state.title != this.state.title
      ) {
        this.setState({ title: t(this.refs.wrapped.state.title) });
      }
      /***Promise**/
      if (
        this.refs.wrapped.beforeOpen != null &&
        typeof this.refs.wrapped.beforeOpen === "function"
      )
        this.refs.wrapped.beforeOpen();

      let afterOpen =
        this.refs.wrapped.afterOpen != null
          ? this.refs.wrapped.afterOpen()
          : null;

      this.setState({ display: "block" });
      setTimeout(() => this.setState({ opacity: 1 }), 10);
      //document.body.classList.add("fix");
    };

    close = () => {
      var isClose = true;

      if (
        this.refs.wrapped.beforeClose != null &&
        typeof this.refs.wrapped.beforeClose === "function"
      ) {
        isClose = this.refs.wrapped.beforeClose() == false ? false : true;
      }

      if (isClose) {
        let afterClose =
          this.refs.wrapped.afterClose != null
            ? this.refs.wrapped.afterClose
            : null;
        this.setState({ opacity: 0 });
        setTimeout(() => this.setState({ display: "none" }, afterClose), 1000);
      }

      document.body.classList.remove("fix");
      //$("html, body").animate({ scrollTop: this.pageYOffset }, 0, "easeInSine");
    };

    setTitle(title) {
      const { t } = this;
      this.setState({ title: t(title) });
    }

    passProps = (passProps, cb) => {
      let callback = () => {
        if (typeof cb == "function") {
          cb();
        }
      };
      this.setState({ passProps }, callback());
    };

    addKeyDownListener = () => {
      setTimeout(() => {
        document.addEventListener('keydown', this.handleKeyDown);
      });
    }

    removeKeyDownListener = () => {
      setTimeout(() => {
        document.removeEventListener('keydown', this.handleKeyDown);
      });
    }

    handleKeyDown = event => {
      if (
        event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27
      ) {
        this.close();
      }
    };

    render() {
      let {
        lightboxClass = "resource_lightbox",
        blockClass = "resourceblock",
      } = this.props;
      const { passProps } = this.state;
      let theTitle = this.state.title || this.props.title;
      return (
        <>
          <div
            className={lightboxClass}
            ref="lightbox"
            style={{
              opacity: this.state.opacity,
              transition: "opacity 1s",
              display: this.state.display,
            }}
          >
            <div className="overlay" onClick={this.close} />
            <div className={blockClass}>
              <div
                className="close"
                tabIndex="0"
                onClick={this.close}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.close(e);
                  };
                }}
                style={{ display: "block" }}
              />
              {theTitle && (<h3>{theTitle}</h3>)}
              <Wrapped
                ref="wrapped"
                {...this.props}
                close={this.close}
                setTitle={this.setTitle}
                {...Config}
                {...passProps}
              />
            </div>
          </div>
        </>
      );
    }
  };

export default LightBox;
