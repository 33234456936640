import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import Login from "./login/Login";
import Logout from "./login/Logout";
import { autobind } from "react-decoration";
import IdleTime from "./common/IdleTime";
import LoginBindLightBox from "components/login/LoginBindLightBox";

@inject("readerStore", "appStore")
@observer
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bindUserType: "",
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    this.props.appStore.setOpenLogin(this.openLogin);
    if (this.props.isprivate && !this.props.readerStore.auth) {
      this.openLogin();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  componentWillReceiveProps(props) {
    if (props.isprivate && !props.readerStore.auth) {
      this.openLogin();
    }
  }

  @autobind
  openLogin() {
    this._login.wrappedComponent._openLogin();
  }

  @autobind
  setLogout() {
    this._logout.wrappedComponent.logoutSubmit();
  }

  @autobind
  openLoginBind(bindUserType) {
    this.setState({ bindUserType });
    this.loginBindLightBox.popup();
  }

  @autobind
  onKeyDown(e) {
    if (e.target.name === "goCenter" && e.keyCode === 13) {
      window.setTimeout(function () { 
        document.getElementById('aC').focus(); 
      }, 0);
    }
  }

  render() {
    let { hiddenBg, isLoginPage, isprivate, appStore } = this.props;
    let { globalConfig } = appStore;
    let cssStyle = globalConfig["jumperrwd.layout.cssStyle"] || "";
    return (
      <>
        {!hiddenBg && (
          <a
            className="goCenter"
            tabIndex="1"
            name="goCenter"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById('center').focus();
            }}>
            按Enter跳到中央內容區塊
          </a>
        )}
        <div className={`wrapper ${cssStyle}`}>
          {!isLoginPage && (
            <>
              <Login
                ref={(c) => {
                  this._login = c;
                }}
                appStore={this.props.appStore}
              />
              <Logout
                ref={(c) => {
                  this._logout = c;
                }}
              />
              <LoginBindLightBox
                ref={(c) => {
                  this.loginBindLightBox = c;
                }}
                lightboxClass="login_lightbox"
                blockClass="loginblock binding"
                userType={this.state.bindUserType}
                readerStore={this.props.readerStore}
                t={this.props.t}
              />
            </>
          )}
          {isprivate && !this.props.readerStore.auth ? (
            <>
              <Header openLogin={this.openLogin} setLogout={this.setLogout} />
              <Footer />
            </>
          ) : (
            <>
              {hiddenBg ? (
                <>{this.props.children}</>
              ) : (
                <>
                  <Header
                    openLogin={this.openLogin}
                    setLogout={this.setLogout}
                    openLoginBind={this.openLoginBind}
                  />
                  {this.props.children}
                  <Footer />
                </>
              )}
            </>
          )}
          <IdleTime t={this.props.t} />
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
