import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import fetch from "isomorphic-fetch";

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: '/api/jumperrwdWs/graphql', credentials: 'include' });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      accept: "*/*",
      "content-type": "application/json",
      'X-CSRF-Token': typeof __GATSBY_MOBX_STORE__ !== `undefined` ? __GATSBY_MOBX_STORE__.readerStore.session.csrfToken : "",
    }
  });
  return forward(operation);
});

export const jumperrwdClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache
});

export default { jumperrwdClient };
