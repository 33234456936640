import React from "react";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { ApolloProvider, Query } from "react-apollo";

const getCaptcha = gql`
  query getCaptcha($typeName: String) {
    getCaptcha(Input: $typeName) {
      success
      message
    }
  }
`;

class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refetch: null,
    };
  }
  render() {
    let typeName = this.props.typeName || "";
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          displayName="graphql"
          query={getCaptcha}
          fetchPolicy="network-only"
          variables={{
            typeName: typeName,
          }}>
          {({ loading, data, refetch, error }) => {
            if (error) return "";
            if (loading) return "Loading...";
            if (this.state.refetch === null) {
              this.setState({ refetch: refetch }, () => {
                if (this.props.getRefetch) {
                  this.props.getRefetch(refetch);
                }
              });
            }
            if (data.getCaptcha !== null)
              return (
                <>
                  <img
                    src={"data:image/svg+xml;base64," + data.getCaptcha.message}
                    alt={this.props.t("jumperrwd.common.captcha")}
                    className="captcha"
                    width="100"
                  />
                  <button
                    type="button"
                    tabIndex="0"
                    className="renew"
                    title="renew"
                    style={{ fontSize: "0px" }}
                    onClick={() => refetch()}>
                    <i className="i_renew"></i>renew
                  </button>
                </>
              );
            else return "Error...";
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default Captcha;
