import PropTypes from "prop-types";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { inject, observer } from "mobx-react";
import React from "react";
import axios from "axios";
import Link from "lib/Link";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

@withI18next(["common"])
@inject("readerStore", "appStore")
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = {
      language: i18n.language,
      showLanguageChange: false,
      showMoreHeader: false,
      showMemberData: false,
      openSubMenuList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.i18n.language != this.state.language) {
      this.setState({ language: nextProps.i18n.language });
    }
  }

  @autobind
  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  @autobind
  showSilderBar(e) {
    e.preventDefault();
    this.sidebar.style.display = "block";
    //$(".sidebar").show();
    this.mArea.style.display = "block";
    //$(".m_area").show();
    this.mArea.style.marginRight = 0;
    /*$(".m_area").animate(
      {
        "margin-right": 0,
      },
      400
    );*/
    document.body.classList.add("noscroll");
    //$("body").addClass("noscroll");
    this.menu_overlay.style.display = "block";
    //$(".menu_overlay").fadeIn();
  }

  @autobind
  closeSilder(e) {
    e.preventDefault();
    this.closeSilderAction();
  }

  @autobind
  closeSilderAction() {
    this.mArea.style.marginRight = -280;
    /*$(".m_area").animate(
      { "margin-right": $(".m_area").width() * -1 + "px" },
      500
    );*/
    this.sidebar.style.display = "none";
    //$(".sidebar").fadeOut(200);
    this.mArea.style.display = "none";
    //$(".m_area").hide();
    document.body.classList.remove("noscroll");
    //$("body").removeClass("noscroll");
    this.menu_overlay.style.display = "none";
    //$(".menu_overlay").fadeOut();
    //$(".menu").find("li.hasChild").children("ul").hide();
    //$(".navigation").find("li.hasChild").children("ul").hide();
  }

  @autobind
  mobileOpenLogin(e) {
    e.preventDefault();
    this.closeSilderAction();
    this.props.openLogin();
  }

  @autobind
  mobileOpenLoginBind(bindUserType) {
    this.closeSilderAction();
    this.props.openLoginBind(bindUserType);
  }

  @autobind
  changeShowMemberData(e) {
    e.preventDefault();
    this.setState({ showMemberData: !this.state.showMemberData });
  }

  handleDocumentClick = (e) => {
    if (
      (!this.moreListBlock || !this.moreListBlock.contains(e.target)) &&
      (!this.languageMenu || !this.languageMenu.contains(e.target)) &&
      (!this.memberRecordMenu || !this.memberRecordMenu.contains(e.target))
    ) {
      this.setState({
        showLanguageChange: false,
        showMoreHeader: false,
        showMemberData: false,
      });
    } else if (this.moreListBlock && this.memberRecordMenu.contains(e.target)) {
      this.setState({
        showLanguageChange: false,
        showMemberData: false,
      });
    } else if (this.languageMenu.contains(e.target)) {
      this.setState({
        showMoreHeader: false,
        showMemberData: false,
      });
    } else if (this.memberRecordMenu.contains(e.target)) {
      this.setState({
        showLanguageChange: false,
        showMoreHeader: false,
      });
    }
  };

  @autobind
  changeSubMenuList(name, e) {
    e.preventDefault();
    if (!this.state.openSubMenuList.includes(name)) {
      let list = this.state.openSubMenuList;
      list.push(name);
      this.setState({
        openSubMenuList: list,
      });
    } else {
      let list = this.state.openSubMenuList;
      list = list.filter((item) => item !== name);
      this.setState({
        openSubMenuList: list,
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
    document.body.classList.remove("noscroll");
  }

  render() {
    let { t, appStore, readerStore, i18n } = this.props;
    let {
      showMemberData,
      showLanguageChange,
      showMoreHeader,
      openSubMenuList,
    } = this.state;
    let { header, footerColumns, globalConfig } = appStore;
    let { session } = readerStore;
    let { realName, unbindUserType = "" } = session;
    let { language } = i18n;
    if (realName !== undefined && realName.length > 4) {
      realName = realName.substring(0, 4);
    }
    if (header === null || header === undefined) {
      return "";
    }
    let { personalList, serviceList, languageList } = header;
    let tImageSrc = "",
      url = "";
    if (globalConfig["jumper.common.toplogo"] !== "0") {
      if (language === "zh") {
        tImageSrc = "/file/images/logo_version.png";
      } else if (language === "en") {
        tImageSrc = "/file/images/logo_version_en.png";
      }
      if (globalConfig["jumper.common.toplogo.linkmod"] !== "1") {
        url = globalConfig["hyint.esource.libraryHomeUrl"];
      } else {
        url = "/";
      }
    }
    let topLogoNewWindow = globalConfig["jumperrwd.header.topLogoNewWindow"] !== "0";
    return (
      <>
        <aside
          className="sidebar"
          ref={(c) => {
            this.sidebar = c;
          }}
          style={{ display: "none" }}>
          <>
            <div
              className="m_area"
              ref={(c) => {
                this.mArea = c;
              }}
              style={{
                marginRight: -280,
              }}>
              <div className="logo">
                <img
                  src={
                    language === "en"
                      ? "/file/images/logo_en.png"
                      : "/file/images/logo.png"
                  }
                  alt={this.props.t("jumper.sys.name")+" Logo"}
                />
              </div>
              <div className="menu">
                {!this.props.readerStore.auth && (
                  <>
                    <button
                      type="button"
                      className="btn_login"
                      onClick={this.mobileOpenLogin}>
                      {t("jumperrwd.common.login")}
                    </button>
                    <div className="language">
                      <select
                        name="language"
                        title="語言"
                        onChange={(e) => {
                          e.preventDefault();
                          this.handleChangeLanguage(e.target.value);
                        }}>
                        {languageList.map((language) => {
                          let { code, label } = language;
                          return <option value={code}>{label}</option>;
                        })}
                      </select>
                    </div>
                  </>
                )}
                <ul>
                  {this.props.readerStore.auth && (
                    <li
                      className={
                        openSubMenuList.includes("cust") ? "openSubmenu" : ""
                      }>
                      <a
                        className="turnicon"
                        onClick={this.changeSubMenuList.bind(this, "cust")}>
                        {t("jumper.common.cust")}
                      </a>
                      <ul>
                        {personalList.map((personal) => {
                          return (
                            <li>
                              <Link
                                href={personal.url}
                                target={
                                  personal.isblank === "1" ? "_blank" : ""
                                }>
                                {personal.langcode === ""
                                  ? personal.name
                                  : t(personal.langcode)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  )}
                  <li
                    className={
                      openSubMenuList.includes("libService")
                        ? "openSubmenu"
                        : ""
                    }>
                    <a
                      className="turnicon"
                      onClick={this.changeSubMenuList.bind(this, "libService")}>
                      {t("jumperrwd.header.libraryService")}
                    </a>
                    <ul>
                      {serviceList.map((service) => {
                        return (
                          <li>
                            <Link
                              href={service.url}
                              target={service.isblank === "1" ? "_blank" : ""}>
                              {service.langcode === ""
                                ? service.name
                                : t(service.langcode)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  {footerColumns !== null &&
                    footerColumns.map((columnData, key) => {
                      return (
                        <li
                          className={
                            openSubMenuList.includes(columnData.title.name)
                              ? "openSubmenu"
                              : ""
                          }>
                          <a
                            className="turnicon"
                            onClick={this.changeSubMenuList.bind(
                              this,
                              columnData.title.name
                            )}>
                            {columnData.title.langcode === ""
                              ? columnData.title.name
                              : t(columnData.title.langcode)}
                          </a>
                          <ul>
                            {columnData.column &&
                              columnData.column.map((i, key) => {
                                return (
                                  <li>
                                    <Link
                                      href={i.url}
                                      target={
                                        i.isblank === "1" ? "_blank" : ""
                                      }>
                                      {i.langcode === ""
                                        ? i.name
                                        : t(i.langcode)}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                </ul>
                {this.props.readerStore.auth && (
                  <>
                    <div className="language">
                      <select
                        name="language"
                        title="語言"
                        onChange={(e) => {
                          e.preventDefault();
                          this.handleChangeLanguage(e.target.value);
                        }}>
                        {languageList.map((language) => {
                          let { code, label } = language;
                          return <option value={code}>{label}</option>;
                        })}
                      </select>
                    </div>
                    {unbindUserType !== "" && (
                      <>
                        <button
                          type="button"
                          tabIndex="0"
                          title="開啟綁定帳號視窗"
                          className={"btn_bind_" + unbindUserType}
                          onClick={(e) => {
                            e.preventDefault();
                            this.mobileOpenLoginBind(unbindUserType);
                          }}>
                          {t("jumperrwd.bindUser.accountBind")}
                        </button>
                      </>
                    )}
                    <button
                      type="button"
                      tabIndex="0"
                      title={t("jumperrwd.common.logout")}
                      className="btn_logout"
                      onClick={this.props.setLogout}>
                      {t("jumperrwd.common.logout")}
                    </button>
                  </>
                )}
              </div>
              <button
                type="button"
                className="sidebarClose"
                onClick={this.closeSilder}>
                {t("jumperrwd.common.close")}
              </button>
            </div>
            <div
              className="menu_overlay"
              ref={(c) => {
                this.menu_overlay = c;
              }}
              onClick={this.closeSilder}></div>
          </>
        </aside>
        <header className="header">
          <div className="container">
            <a class="accesskey" id="aU" title="網站標題" tabindex="2">:::</a>
            <button
              type="button"
              className="sidebarCtrl"
              onClick={this.showSilderBar}>
              {t("jumperrwd.header.slideBar")}
            </button>
            <h1>
              {tImageSrc !== "" ? (
                <Link href={url} target={topLogoNewWindow ? "_blank" : ""}>
                  <img src={tImageSrc} alt={this.props.t("jumper.sys.name")+"Logo"} />
                  <span>{t("jumperrwd.header.mobileTitle")}</span>
                </Link>
              ) : (
                <span>{t("jumperrwd.header.mobileTitle")}</span>
              )}
            </h1>
            <nav className="navigation">
              <ul>
                {serviceList.map((service, k) => {
                  if (k < 4) {
                    return (
                      <li>
                        <Link
                          href={service.url}
                          target={service.isblank === "1" ? "_blank" : ""}>
                          {service.langcode === ""
                            ? service.name
                            : t(service.langcode)}
                        </Link>
                      </li>
                    );
                  }
                  return "";
                })}
              </ul>
              {serviceList.length > 4 && (
                <div
                  className="more_list_block dropmenu"
                  ref={(c) => {
                    this.moreListBlock = c;
                  }}>
                  <button
                    type="button"
                    className="more"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showMoreHeader: !showMoreHeader,
                      });
                    }}>
                    {t("jumperrwd.header.showMore")}
                  </button>
                  {showMoreHeader && (
                    <ul
                      className="more_list"
                      ref={(c) => {
                        this.more_list = c;
                      }}
                      style={{ display: showMoreHeader ? "block" : "none" }}>
                      {serviceList.map((service, k) => {
                        if (k > 3) {
                          return (
                            <li>
                              <Link
                                href={service.url}
                                target={
                                  service.isblank === "1" ? "_blank" : ""
                                }>
                                {service.langcode === ""
                                  ? service.name
                                  : t(service.langcode)}
                              </Link>
                            </li>
                          );
                        }
                        return "";
                      })}
                    </ul>
                  )}
                </div>
              )}
            </nav>
            <div className="nav_right">
              <div
                className="language dropmenu"
                ref={(c) => {
                  this.languageMenu = c;
                }}>
                <a
                  tabIndex="0"
                  title={"顯示可變更的語言"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showLanguageChange: !showLanguageChange });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ showLanguageChange: !showLanguageChange });
                    };
                  }}>
                  {t("jumper.common.selectlang")}
                </a>
                {showLanguageChange && (
                  <ul
                    style={{ display: showLanguageChange ? "block" : "none" }}>
                    {languageList.map((language) => {
                      let { code, label } = language;
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={"語言變更為"+label}
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleChangeLanguage(code);
                              this.setState({ showLanguageChange: false });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.handleChangeLanguage(code);
                                this.setState({ showLanguageChange: false });
                              };
                            }}>
                            {label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              {this.props.readerStore.auth ? (
                <div
                  className="member_record dropmenu"
                  ref={(c) => {
                    this.memberRecordMenu = c;
                  }}>
                  <div className="member_name">{realName}</div>
                  <button
                    type="button"
                    className="btn_member"
                    onClick={this.changeShowMemberData}>
                    <img src="/file/images/icon/icon_user.svg" alt="使用者" />
                  </button>
                  {showMemberData && (
                    <div
                      className="member_data"
                      style={{ display: showMemberData ? "block" : "none" }}>
                      <ul>
                        <li>
                          <Link href="/custList">
                            {t("jumper.common.cust")}
                          </Link>
                        </li>
                        {unbindUserType !== "" && (
                          <>
                            <li>
                              <a
                                className={"bind_" + unbindUserType}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.openLoginBind(unbindUserType);
                                }}>
                                {t("jumperrwd.bindUser.accountBind")}
                              </a>
                            </li>
                          </>
                        )}
                        <li>
                          <a
                            className="signout"
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeShowMemberData(e);
                              this.props.setLogout(e);
                            }}>
                            {t("jumperrwd.common.logout")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <div className="login loginbtn">
                  <button
                    type="button"
                    className="btn"
                    onClick={this.props.openLogin}>
                    {t("jumperrwd.common.login")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
      </>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
