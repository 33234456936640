import React from "react";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import { navigate } from "gatsby";

const setLogout = gql`
  mutation setLogout {
    result: setLogout {
      success
      message
    }
  }
`;

@inject("readerStore","appStore")
@observer
@withI18next(["common"])
class Logout extends React.Component {
  constructor(props) {
    super(props);
    let alertHiddenString = props.appStore.globalConfig["jumperrwd.login.alertHidden"];
    let alertHidden = alertHiddenString === "1";
    this.state = {
      loading: false,
      alertHidden: alertHidden,
    };
  }

  @autobind
  async logoutSubmit() {
    if (this.state.loading === false) {
      this.setState({
        loding: true,
      });
      client.jumperrwdClient
        .mutate({
          mutation: setLogout,
          fetchPolicy: "no-cache",
        })
        .then(async (data) =>  {
          if (data.data.result.success) {
            if (!this.state.alertHidden) {
              alert(this.props.t("jumperrwd.login.logoutSuccess"));
            }
            await this.props.readerStore.syncSessionCookie().then(() => {
              let toHome = this.props.appStore.globalConfig["jumperrwd.login.logoutToHome"] === "1";
              if (toHome) {
                navigate("/");
              }
            });
          } else {
            alert(this.props.t("jumperrwd.login.logoutFailed"));
          }
          this.setState({
            loading: false,
          });
        });
    }
  }

  render() {
    return <div />;
  }
}

export default Logout;
