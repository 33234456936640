import React from "react";
import LightBox from "components/common/LightBox";
import { autobind } from "react-decoration";
import qs from "query-string";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { ApolloProvider, Mutation } from "react-apollo";
import ReactHtmlParser from "react-html-parser";

const setLoginBind = gql`
  mutation setLoginBind($form: LoginForm) {
    result: setLoginBind(Input: $form) {
      success
      errorType
    }
  }
`;

@LightBox
class LoginBindLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      password: "",
      bindMessage: "",
      bindDisable: false,
      processBind: false,
    };
  }

  @autobind
  handleUser(e) {
    this.setState({
      user: e.target.value,
    });
  }

  @autobind
  handlePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  @autobind
  _reset() {
    this.setState({
      user: "",
      password: "",
      bindMessage: "",
      loginDisable: false,
      processLogin: false,
    });
  }

  beforeClose = () => {
    this._reset();
    if (this.props.bindAlertLightBoxClose !== undefined) {
      this.props.bindAlertLightBoxClose();
    }
  };

  @autobind
  async handleLogin(data) {
    if (data.result.success) {
      alert(this.props.t("jumperrwd.bindUser.success"));
      await this.props.readerStore.syncSessionCookie().then(() => {
        if (this.props.isPage) {
          if (this.props.appStore.loginRedirect !== null) {
            navigate(this.props.appStore.loginRedirect);
          } else {
            navigate("/");
          }
        } else {
          this.props.close();
        }
      });
    } else {
      alert(this.props.t("jumperrwd.bindUser.failed"));
    }
    this.setState({ bindDisable: false, processBind: false });
  }

  @autobind
  loginBindSubmit(setLoginBind, e) {
    e.preventDefault();
    if (this.state.user !== "" && this.state.password !== "") {
      this.setState({
        bindDisable: true,
        processBind: true,
      });
      setLoginBind({
        variables: {
          form: {
            user: this.state.user,
            password: this.state.password,
            userType: this.props.bindUserType,
          },
        },
      });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <div className="login_form">
        <h2>{t("jumperrwd.bindUser.accountBind")}</h2>
        {ReactHtmlParser(
          t("jumperrwd.bindUser." + this.props.bindUserType + "About")
        )}
        <div className="form_content">
          <div className="form_grp form_inline">
            <label htmlFor="user">
              {t("jumperrwd.bindUser." + this.props.bindUserType + "User")}
            </label>
            <input
              id="user"
              name="user"
              type="text"
              value={this.state.user}
              onChange={this.handleUser}
            />
          </div>
          <div className="form_grp form_inline">
            <label htmlFor="password">
              {t("jumperrwd.bindUser." + this.props.bindUserType + "Pwd")}
            </label>
            <input
              id="password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handlePassword}
            />
          </div>
          <div className="btn_grp form_inline">
            <ApolloProvider client={client.jumperrwdClient}>
              <Mutation
                mutation={setLoginBind}
                onError={() => {
                  this.setState({
                    bindMessage: "jumperrwd.bindUser.bindError",
                  });
                }}
                onCompleted={(data) => this.handleLogin(data)}>
                {(setLoginBind) => (
                  <button
                    tabIndex="0"
                    className="btn btn-submit"
                    disabled={this.state.bindDisable}
                    onClick={this.loginBindSubmit.bind(this, setLoginBind)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.loginBindSubmit(setLoginBind, e);
                      }
                    }}>
                    {t("jumperrwd.common.submit")}
                  </button>
                )}
              </Mutation>
            </ApolloProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginBindLightBox;
