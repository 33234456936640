import React from "react";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import ReactHtmlParser from "react-html-parser";
import Link from "lib/Link";

@withI18next(["common"])
@inject("appStore")
@observer
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let { t, appStore } = this.props;
    let { footerColumns } = appStore;
    if (footerColumns === undefined) {
      return "";
    }
    return (
      <>
        <footer>
          <div className="container">
            <nav>
              <ul>
                {footerColumns !== null &&
                  footerColumns.map((columnData, key) => {
                    return (
                      <li>
                        <Link href={columnData.url} target={columnData.isblank === "1" ? "_blank" : ""}>
                          {columnData.title.langcode === ""
                            ? columnData.title.name
                            : t(columnData.title.langcode)}
                        </Link>
                        <ul>
                          {columnData.column && columnData.column.map((i, key) => {
                            return (
                              <li>
                                <Link
                                  href={i.url}
                                  title={i.name==="使用手冊"?"使用手冊.pdf":""}
                                  target={i.isblank === "1" ? "_blank" : ""}>
                                  {i.langcode === "" ? i.name : t(i.langcode)}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </nav>
            <div className="footer_info">
              {ReactHtmlParser(t("jumperrwd.footer.info"))}
            </div>
          </div>
        </footer>
      </>
    );
  }
}

Footer.propTypes = {};

export default Footer;
