import React from "react";
import LightBox from "components/common/LightBox";
import { autobind } from "react-decoration";
import { inject, observer } from "mobx-react";
import axios from "axios";
import moment from "moment";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { navigate } from "gatsby";
import { ApolloProvider, Query } from "react-apollo";
import ReactLoading from "react-loading";
import ReactHtmlParser from "react-html-parser";

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  "Pragma": "no-cache",
  "Expires": "0"
};

const setLogout = gql`
  mutation setLogout {
    result: setLogout {
      success
      message
    }
  }
`;

const getLoginConfig = gql`
  query getLoginConfig($configType: String) {
    getLoginConfig(Input: $configType) {
      guestLogin
      guestLoginMessage
    }
  }
`;

@inject("readerStore", "appStore")
@observer
class IdleTime extends React.Component {
  constructor(props) {
    super(props);
    let { appStore } = this.props;
    let { globalConfig } = appStore;
    let reminderIdleTime = globalConfig["jumper.common.logintimeout"] * 60;
    let countdownIdleTime = globalConfig["jumper.common.logintimeout2"] * 60;
    let directlogout = globalConfig["jumper.common.logintimeout.directlogout"];
    this.state = {
      reminderDisplay: "none",
      logoutDisplay: "none",
      reminderIdleTime: reminderIdleTime,
      countdownIdleTime: countdownIdleTime,
      defaultReminderIdleTime: reminderIdleTime,
      defaultCountdownIdleTime: countdownIdleTime,
      directlogout: directlogout === "1", //登入後停留超時後直接登出
      auth: this.props.readerStore.auth,
      loading: false,
    };
  }

  componentDidMount() {
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    if (this.props.readerStore.auth) {
      this.events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      let expireDate = moment()
        .add(this.state.defaultReminderIdleTime, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      axios
        .get(`/api/jumperrwdWs/setSession?key=idle&value=${expireDate}`)
        .then((res) => {});

      this.checkIdleInterval = setInterval(() => {
        // console.log(this.state.reminderIdleTime);
        if (this.state.reminderIdleTime == 0) {
          if (this.props.readerStore.auth) {
            this.confirmCountdown();
          } else {
            clearInterval(this.checkIdleInterval);
          }
          return;
        } else {
          this.setState({
            reminderIdleTime: this.state.reminderIdleTime - 1,
          });
        }
      }, 1000);

      document.addEventListener(visibilityChange, this.handleVisibilitychange);
    } else {
      document.addEventListener(visibilityChange, this.handleVisibilitychange2);
    }
  }

  componentWillUnmount() {
    var visibilityChange, hidden;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    document.removeEventListener(
      visibilityChange,
      this.handleVisibilitychange
    );
    document.removeEventListener(
      visibilityChange,
      this.handleVisibilitychange2
    );
    clearInterval(this.checkIdleInterval);
  }

  componentWillReceiveProps(props) {
    if (this.state.auth != this.props.readerStore.auth) {
      this.setState({ auth: this.props.readerStore.auth });

      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      if (this.props.readerStore.auth) {
        this.events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress",
        ];

        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.checkIdleInterval = setInterval(() => {
          // console.log(this.state.reminderIdleTime);
          if (this.state.reminderIdleTime == 0) {
            if (this.props.readerStore.auth) {
              this.confirmCountdown();
            } else {
              clearInterval(this.checkIdleInterval);
            }
            return;
          } else {
            this.setState({
              reminderIdleTime: this.state.reminderIdleTime - 1,
            });
          }
        }, 1000);

        document.removeEventListener(
          visibilityChange,
          this.handleVisibilitychange2
        );
        document.addEventListener(
          visibilityChange,
          this.handleVisibilitychange
        );
      } else {
        document.removeEventListener(
          visibilityChange,
          this.handleVisibilitychange
        );
        document.addEventListener(
          visibilityChange,
          this.handleVisibilitychange2
        );
        clearInterval(this.checkIdleInterval);
      }
    }
  }

  @autobind
  handleVisibilitychange() {
    var hidden;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
    }

    const isHidden = document[hidden];

    if (isHidden) {
      // console.log("hidden");
      clearInterval(this.checkIdleInterval);
      let expireDate = moment()
        .add(this.state.defaultReminderIdleTime, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      axios
        .get(`/api/jumperrwdWs/setSession?key=idle&value=${expireDate}`)
        .then((res) => {});
    } else {
      let lastAuth = this.state.auth;
      this.props.readerStore.syncSessionCookie().then(() => {
        let isIdle = moment().isAfter(this.props.readerStore.session.idle);
        let confirmexpireDate = moment(this.props.readerStore.session.idle)
          .add(this.state.defaultCountdownIdleTime, "seconds")
          .format("YYYY-MM-DD HH:mm:ss");
        let isConfirmIdle = moment().isAfter(confirmexpireDate);
        let auth = this.props.readerStore.auth;

        // console.log("confirmexpireDate", confirmexpireDate);
        // console.log("idle", this.props.readerStore.session.idle);
        // console.log("auth", auth);
        // console.log("isIdle", isIdle);
        // console.log("isConfirmIdle", isConfirmIdle);

        if (isIdle && auth) {
          if (isConfirmIdle) {
            this.handleLogout(true);
          } else {
            this.confirmCountdown();
          }
        } else if (!auth) {
          clearInterval(this.checkIdleInterval);
          clearInterval(this.checkIdleInterval2);
          this.setState({
            reminderDisplay: "none",
            logoutDisplay: "block",
          });
        } else {
          this.setState(
            { reminderIdleTime: this.state.defaultReminderIdleTime },
            () => {
              clearInterval(this.checkIdleInterval);
              this.checkIdleInterval = setInterval(() => {
                // console.log(this.state.reminderIdleTime);
                if (this.state.reminderIdleTime == 0) {
                  if (this.props.readerStore.auth) {
                    this.confirmCountdown();
                  }
                  return;
                } else {
                  this.setState({
                    reminderIdleTime: this.state.reminderIdleTime - 1,
                  });
                }
              }, 1000);
            }
          );
        }
      });
    }
  }

  @autobind
  handleVisibilitychange2() {
    var hidden;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
    }

    const isHidden = document[hidden];

    if (!isHidden) {
      this.props.readerStore.syncSessionCookie();
    }
  }

  @autobind
  resetTimeout() {
    this.setState({
      reminderIdleTime: this.state.defaultReminderIdleTime,
    });
  }

  @autobind
  async confirmCountdown() {
    this.setState({
      reminderDisplay: "block",
      countdownIdleTime: this.state.defaultCountdownIdleTime,
    });
    clearInterval(this.checkIdleInterval);
    clearInterval(this.checkIdleInterval2);
    this.countdown();
  }

  @autobind
  handleLogout(writeLog) {
    clearInterval(this.checkIdleInterval);
    clearInterval(this.checkIdleInterval2);
    // console.log("logout");
    if (this.state.loading === false) {
      this.setState({
        loding: true,
      });
      client.jumperrwdClient
        .mutate({
          mutation: setLogout,
          fetchPolicy: "no-cache",
        })
        .then(async (data) => {
          if (data.data.result.success) {
            await this.props.readerStore.syncSessionCookie();
            this.setState({
              reminderDisplay: "none",
              logoutDisplay: "block",
            });
          } else {
            alert(this.props.t("jumperrwd.login.logoutFailed"));
          }
          this.setState({
            loading: false,
          });
        });
    }
  }

  @autobind
  handleConfirm() {
    this.resetTimeout();
    this.setState({
      reminderDisplay: "none",
    });
    clearInterval(this.checkIdleInterval);
    clearInterval(this.checkIdleInterval2);

    if (this.props.readerStore.auth) {
      this.checkIdleInterval = setInterval(() => {
        // console.log(this.state.reminderIdleTime);
        if (this.state.reminderIdleTime == 0) {
          if (this.props.readerStore.auth) {
            this.confirmCountdown();
          } else {
            clearInterval(this.checkIdleInterval);
          }
          return;
        } else {
          this.setState({
            reminderIdleTime: this.state.reminderIdleTime - 1,
          });
        }
      }, 1000);
    }
  }

  @autobind
  countdown() {
    this.checkIdleInterval2 = setInterval(() => {
      // console.log("confirm ", this.state.countdownIdleTime);
      if (this.state.countdownIdleTime == 0) {
        if (this.props.readerStore.auth) {
          this.handleLogout(true);
        }
        return;
      } else {
        this.setState({
          countdownIdleTime: this.state.countdownIdleTime - 1,
        });
      }
    }, 1000);
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <div
          className="reminder_lightbox"
          ref="reminder_lightbox"
          style={{ display: this.state.reminderDisplay }}>
          <div className="overlay" />
          <div className="reminderblock">
            <h3>{t("jumperrwd.common.idleTimeout")}</h3>
              {ReactHtmlParser(t("jumperrwd.idle.timeoutMessage", { minute: this.state.defaultReminderIdleTime / 60 }))}
            <div className="btn_grp">
              <button
                className="btn btn-reset"
                tabIndex="0"
                onClick={this.handleLogout.bind(true)}>
                {t("hyint.common.no")}({this.state.countdownIdleTime})
              </button>
              <button tabIndex="0" className="btn btn-submit" onClick={this.handleConfirm}>
              { t("hyint.common.yes")}
              </button>
            </div>
          </div>
        </div>
        {this.state.logoutDisplay === "block" && <div
          className="reminder_lightbox"
          ref="reminder_lightbox"
          style={{ display: this.state.logoutDisplay }}>
          <div className="overlay" />
          <div className="reminderblock">
            <h3>{t("jumperrwd.idle.loggedOutTitle")}</h3>
              {ReactHtmlParser(t("jumperrwd.idle.reLoginMessage", { minute: this.state.defaultReminderIdleTime / 60 }))}
            <ApolloProvider client={client.jumperrwdClient}>
              <Query
                query={getLoginConfig}
                fetchPolicy="network-only"
                variables={{
                  configType: "page",
                }}>
                {({ loading, error, data, refetch }) => {
                  if (loading)
                    return (
                      <center>
                        <ReactLoading
                          type="cylon"
                          height={"10%"}
                          width={"20%"}
                          color="#005D98"
                        />
                      </center>
                    );
                  if (error) return `Error!${error.message}`;

                  if (data.getLoginConfig) {
                    return (
                      <div className="btn_grp">
                        {data.getLoginConfig.guestLogin && (
                          <button
                            className="btn btn-nostyle"
                            className="btn btn-submit"
                            tabIndex="0"
                            onClick={() => {
                              this.setState({
                                reminderDisplay: "none",
                                logoutDisplay: "none",
                              });
                              navigate("/");
                            }}>
                            {this.props.t(data.getLoginConfig.guestLoginMessage) === "" ? "以訪客身份瀏覽" : this.props.t(data.getLoginConfig.guestLoginMessage)}
                          </button>
                        )}
                        <button
                          className="btn btn-submit"
                          tabIndex="0"
                          onClick={() => {
                            this.setState({
                              reminderDisplay: "none",
                              logoutDisplay: "none",
                            });
                            navigate("/login");
                          }}>
                          {t("jumperrwd.common.reLogin")}
                        </button>
                      </div>
                    );
                  }
                  return "";
                }}
              </Query>
            </ApolloProvider>
          </div>
        </div>}
      </>
    );
  }
}

export default IdleTime;
