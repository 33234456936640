import React from "react";
import LightBox from "components/common/LightBox";
import { autobind } from "react-decoration";
import qs from "query-string";
import { navigate } from "gatsby";
import ReactHtmlParser from "react-html-parser";

@LightBox
class LoginBindAlertLightBox extends React.Component {
  constructor(props) {
    super(props);

    let alertCannotSkipUserTypeString =
      props.appStore.globalConfig["jumperrwd.loginBind.alertCannotSkipUserType"];

    let alertCannotSkipUserTypes = [];
    if (alertCannotSkipUserTypeString !== undefined && alertCannotSkipUserTypeString !== "") {
      alertCannotSkipUserTypes = alertCannotSkipUserTypeString.split(",");
    }

    let cannotSkip = alertCannotSkipUserTypes.includes(props.bindUserType);

    this.state = {
      ...props,
      alertCannotSkipUserTypes,
      cannotSkip,
    };
  }

  componentWillReceiveProps(props) {
    if (props.bindUserType !== this.state.bindUserType) {
      let cannotSkip = this.state.alertCannotSkipUserTypes.includes(props.bindUserType);
      this.setState({
        cannotSkip: cannotSkip,
        bindUserType: props.bindUserType,
      });
    }
  }

  @autobind
  toBind(e) {
    e.preventDefault();
    this.props.openLoginBindLightBox();
  }

  beforeClose = () => {
    this.props.loginLightBoxClose();
  };

  render() {
    let { t } = this.props;
    return (
      <div className="reminderblock">
        {ReactHtmlParser(
          t("jumperrwd.bindUserAlert." + this.props.bindUserType + "About")
        )}
        <div className="btn_grp">
          {!this.state.cannotSkip && (
            <button
              className="btn btn-submit"
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                this.props.close();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.props.close(e);
                }
              }}>
              {t("jumperrwd.bindUserAlert.skip")}
            </button>
          )}
          <button
            className="btn btn-submit"
            tabIndex="0"
            onClick={this.toBind}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.toBind(e);
              }
            }}>
            {t("jumperrwd.bindUserAlert.toBind")}
          </button>
        </div>
      </div>
    );
  }
}

export default LoginBindAlertLightBox;
